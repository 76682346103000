import {CRYPTO_CURRENCIES} from '@/modules/currencies/constants/currencies.constants';
import { BtcTransfer } from '../interfaces/exchangeApis/btcTransfer.interface';
import { TronTransfer } from '../interfaces/exchangeApis/tronTransfer.interface';
import { btcRepository } from '../repositories/btc.httpRepository';
import { tronScanRepository } from '../repositories/tronScan.httpRepository';

const BTC_DIV=100000000;
export async function txIdValidation(currency: string,txId:string,wallets:any): Promise<{success: boolean,amount?:number,date?: number,message?: string}>{
    try{
        if (txId.toLowerCase().startsWith('tx')) return {success:false,message:"El txId proporcionado no debe iniciar con 'Tx'"}
        switch(currency){
            case CRYPTO_CURRENCIES.BTC:
                let btcResponse: BtcTransfer = await btcRepository.getTransactionByTxId(txId)
                if (!btcResponse.data) throw new Error(); 
                let data = btcResponse.data.outputs.find(el=> el.addresses.find(ad=>ad === wallets[CRYPTO_CURRENCIES.BTC]))
                if (btcResponse.data.hash === txId && data)
                    return  {success:true,amount:data.value/BTC_DIV,date: btcResponse.data.block_time}
                return {success:false,message: 'TxId inválido. Verifique de nuevo su TxId'}
            case CRYPTO_CURRENCIES.USDT:
                let tronResponse: TronTransfer = await tronScanRepository.getTransactionByTxId(txId);
                let tronData = tronResponse.tokenTransferInfo;
                if (tronResponse.hash === txId && tronData.to_address === wallets[CRYPTO_CURRENCIES.USDT])
                    return {success:true,amount:parseInt(tronData.amount_str)/thousandsConverter(tronData.decimals),date: tronResponse.timestamp}
                return {success:false,message: 'TxId inválido. Verifique de nuevo su TxId'}
            default:
                return {success:false,message: 'TxId inválido. Verifique de nuevo su TxId'}
        }
    }catch(e){
        return {success:false,message:'TxId inválido. Verifique de nuevo su TxId'}
    }
}

function thousandsConverter(quantity: number){
    let thousandNumber = 1;
    for (var i=0;i<quantity;i++){
        thousandNumber=thousandNumber*10
    }
    return thousandNumber
}


export function confirmedTxIdBadgeColor(txIdConfirmation: boolean): string{
    switch(txIdConfirmation){
        case true:
            return  'badge-sent'
        case false:
            return  'badge-toBeChecked'
        default:
            return 'badge-rejected'
    }
}