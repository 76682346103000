export const EXCHANGE_TYPES={
    BUY: 'COMPRA',
    SELL: 'VENTA',
    DEPOSIT: 'DEPOSITO',
    WITHDRAWAL: 'RETIRO',
    CONVERSION: 'CONVERSION'
}

export const CAMELCASE_EXCHANGE_TYPES={
    BUY: 'Compra',
    SELL: 'Venta',
    DEPOSIT: 'Depósito',
    WITHDRAWAL: 'Retiro',
    CONVERSION: 'Conversión'
}