
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';

@Component({
    components: {
        SvgIcon
    },
})
export default class Calculator extends Vue {
    @Prop() label:{
        text: string,
        size: string
    }
    @Prop() disable?: boolean;
    @Prop() hideLabel?: boolean;
    countries = [
        {name: 'chile', iso_code: 'cl', text: 'PEN'},
        {name: 'venezuela', iso_code: 've', text: 'VEF'}
    ]

}
