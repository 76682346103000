import axios from 'axios';
import { BtcTransfer } from '../interfaces/exchangeApis/btcTransfer.interface';

export class BtcRepository{

    private baseApiUrl = 'https://chain.api.btc.com/v3/'

    public async getTransactionByTxId(txId: string): Promise<BtcTransfer> {
        return (await (axios.get(this.baseApiUrl+'tx/'+txId+'?verbose=3'))).data
    }
}

export const btcRepository = new BtcRepository();