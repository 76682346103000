
import { Component, Vue, Watch,Prop } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import {auth} from '@/store/namespaces'
import AuthMethods from '@/store/auth/methods/auth.methods'
import { User } from '@/modules/auth/interfaces/user.interface';
import {VL_NOTIFICATIONS} from '@/modules/verificationLevels/constants/verifLevels.constants'
@Component({
    components: {
        SvgIcon,
    },
})
export default class InfoBanner extends Vue {
    @Prop() title!: string;
    @Prop() subtitle!: string;



    @Watch('userData.id_verif_level')
    watchVerifLevel(newVal: number){

    }
    @Watch('userData.verif_level_apb')
    watchVerifLevelApb(newVal: boolean){
        
    }
}
