
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* STORE */
import { auth } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods'
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
/* INTERFACES */
import {Remittance} from '@/modules/remittance/interfaces/remittance.interface'
import {Exchange} from '@/modules/exchange/interfaces/exchange.interface';
/* CONSTANTS */
import { REMITTANCE_PUBLIC_STATUS } from '@/modules/remittance/constants/remittance.constants';
import {CLAIM_STATUS_ARRAY} from '@/modules/exchange/constants/status.constants'
import {CAMELCASE_EXCHANGE_TYPES} from '@/modules/exchange/constants/exchangeTypes.constants'
import {CURRENCY_FORMAT_CONFIG} from '@/modules/currencies/constants/currencies.constants';
import {RATE_OPERATIONS} from '@/modules/rates/constants/rate.constants';
import {COUNTRIES_ISO_CODES} from '@/modules/countries/constants/countriesISOCodes.constants';
/* FUNCTIONS */
import { epochToDateAndHour } from '@/utils/date-functions';
import { remittanceBadgeColor } from '@/modules/remittance/functions/remittance.functions';
import { reverseCurrencyConvertion } from '@/modules/rates/functions/rates.functions';
import { Rate } from '@/modules/rates/interfaces/rate.interface';
import { hideEmail, hideSomeCharacters } from '@/utils/global-functions';
import {confirmedTxIdBadgeColor} from '@/modules/exchange/functions/txIdValidation.function'
import {getPairs,getPairOperation} from '@/modules/exchange/functions/pairs.functions'

@Component({
    components: {
        SvgIcon
    }
})
export default class ExchangeDetailPopup extends Vue {
    @Prop() showModal!: boolean;
    @Prop() exchange!: Exchange;
    show: boolean = false;
    ratesOperations = RATE_OPERATIONS;
    publicStatus =  REMITTANCE_PUBLIC_STATUS;
    claimStatus = CLAIM_STATUS_ARRAY;
    exchangeTypes = CAMELCASE_EXCHANGE_TYPES;
    currenciesFormatConfig = CURRENCY_FORMAT_CONFIG;

    /* MODAL */
    @Watch('showModal')
     renderModal(){
        this.show = this.showModal;
    }

    @Watch('show')
    sendToParent(newVal: boolean){
        this.$emit('changeModalStatus', newVal)
    }
    /* END MODAL */

    getRemittanceBadgeColor(status: string){
       return remittanceBadgeColor(status)
    }

    getConfirmedTxIdBadgeColor(status: boolean){
       return confirmedTxIdBadgeColor(status)
    }

    dateAndHour(epoch: number){
      return epochToDateAndHour(epoch)
    }

    currencyConvertion(amount: number, rate: Rate){
        return this.$options.filters.currency(reverseCurrencyConvertion(rate,amount))
    }

    sendConsultExchangeMessage(){
        this.$router.push({name: 'Chat', params:{message: `¡Hola! Quiero realizar una consulta con respecto a mi ${this.exchange.exchangeType.name} de criptomonedas Nro. ${this.exchange.idExchangePub}, realizada el día ${this.exchange.dateCreated}`}}).catch();
    }

    hideEmail(email: string){
       return hideEmail(email)
    }

    hideSomeCharacters(text: string,init:number,end: number){
        return hideSomeCharacters(text,init,end)
    }

    get pair(){
        return getPairs(this.exchange.route.origin_iso_code,this.exchange.route.destiny_iso_code)
    }

    get operation(){
        return getPairOperation(this.pair,this.exchange.route.origin_iso_code)
    }

    get exchangeInitialAmount(){
        switch(this.exchange.exchangeType.name){
            case this.exchangeTypes.BUY:
                return 'Depositado'
            case this.exchangeTypes.SELL:
                return 'Vendido'
            case this.exchangeTypes.WITHDRAWAL:
                return 'Retirado'
            case this.exchangeTypes.DEPOSIT:
                return 'Depositado'
            case this.exchangeTypes.CONVERSION:
                return 'Enviado'
            default:
                return null
        }
    }

    get exchangeNetAmount(){
        switch(this.exchange.exchangeType.name){
            case this.exchangeTypes.BUY:
                return 'Enviado'
            case this.exchangeTypes.SELL:
                return 'Recibido'
            case this.exchangeTypes.WITHDRAWAL:
                return 'Recibido'
            case this.exchangeTypes.DEPOSIT:
                return null
            default:
                return null
        }
    }

    get exchangeNetDestinyAmount(){
        switch(this.exchange.exchangeType.name){
            case this.exchangeTypes.BUY:
                return true
            case this.exchangeTypes.SELL:
                return true
            case this.exchangeTypes.WITHDRAWAL:
                return false
            case this.exchangeTypes.DEPOSIT:
                return true
            case this.exchangeTypes.CONVERSION:
                return true
            default:
                return false
        }
    }

    get exchangeFee(){
        switch(this.exchange.exchangeType.name){
            case this.exchangeTypes.BUY:
                return 'Bancaria'
            case this.exchangeTypes.SELL:
                return 'Bancaria'
            case this.exchangeTypes.WITHDRAWAL:
                return 'de Red'
            case this.exchangeTypes.DEPOSIT:
                return null
            default:
                return null
        }
    }

    get isKrakenUser(){
        if (this.user.iso_code_resid_country === COUNTRIES_ISO_CODES.ES) return true
        return false
    }
    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    user!: User;
}
    
