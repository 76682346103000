import axios from 'axios';
import { TronTransfer } from '../interfaces/exchangeApis/tronTransfer.interface';

export class TronScanRepository{

    private baseApiUrl = 'https://apilist.tronscan.org/api/'

    public async getTransactionByTxId(txId: string): Promise<TronTransfer> {
        return (await (axios.get(this.baseApiUrl+'transaction-info?hash='+txId))).data
    }
}

export const tronScanRepository = new TronScanRepository();