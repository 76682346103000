
import { Component, Vue, Watch } from 'vue-property-decorator';
/* STORE */
import { auth ,remittance,countries,rates, notifications,exchange} from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import RemittanceMethods from '@/store/remittance/methods/remittance.methods'
import CountriesMethods from '@/store/countries/methods/countries.methods'
import RatesMethods from '@/store/rates/methods/rates.methods'
import NotificationsMethods from '@/store/notifications/methods/verificationLevels.methods'
import ExchangeMethods from '@/store/exchange/methods/exchange.methods'
/* INTERFACE */
import { User } from '@/modules/auth/interfaces/user.interface';
import { Country, CountryCurrency } from '@/modules/countries/interfaces/countries.interface';
import { PreRemittance } from '@/modules/remittance/interfaces/preRemittance.interface';
import { FullRate, Rate } from '@/modules/rates/interfaces/rate.interface';
import { RangeRate } from '@/modules/rates/interfaces/rangeRate.interface';
import { RateType } from '@/modules/rates/interfaces/rateType.interface';
import { Remittance } from '@/modules/remittance/interfaces/remittance.interface';
import { Notif } from '@/modules/notifications/interfaces/notification.interface';
import {Exchange, PreExchange} from '@/modules/exchange/interfaces/exchange.interface'
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import CountryInputDropwdown from '@/components/utilities/CountryInputDropdown.vue'
import Toast from '@/components/utilities/Toast.vue';
import InfoBanner from '@/components/utilities/InfoBanner.vue';
import AppearTransition from '@/components/utilities/transitions/AppearTransition.vue';
import Table from '@/components/tables/Table.vue';
import ConfirmationModal from '@/components/utilities/ConfirmationModal.vue';
import Loader from '@/components/utilities/Loader.vue';
import InfoModal from '@/components/utilities/InfoModal.vue'
import SendRemittancePopup from '@/modules/remittance/components/sendRemittance/popups/SendRemittancePopup.vue'
import RemittanceTypePopup from '@/modules/remittance/components/sendRemittance/popups/RemittanceTypePopup.vue'
import Calculator from '@/modules/remittance/components/Calculator.vue'
import RemittanceDetailPopup from '@/modules/remittance/components/RemittanceDetailPopup.vue';
import ExchangeDetailPopup from '@/modules/exchange/components/ExchangeDetailPopup.vue';
import NoRemittancePopup from '@/modules/verificationLevels/components/NoRemittancePopup.vue';
import WelcomePopup from '@/modules/auth/components/WelcomePopup.vue';
/* CONSTANTS */
import { getVerifLevelNotifications } from '@/modules/verificationLevels/constants/verifLevels.constants';
import { REMITTANCE_PUBLIC_STATUS, REMITTANCE_TYPES } from '@/modules/remittance/constants/remittance.constants';
import {CAMELCASE_EXCHANGE_TYPES} from '@/modules/exchange/constants/exchangeTypes.constants';
import { MANUAL_RATES, RATE_CATEGORIES, RATE_TYPES } from '@/modules/rates/constants/rate.constants';
import { CRIPTOREMESA, WHATSAPP_PHONES, EUROPE_PHONE } from '@/constants/criptoremesa.constants';
import {CLAIM_STATUS_ARRAY} from '@/modules/exchange/constants/status.constants';
import {NO_REMITTANCE_COUNTRIES} from '@/modules/countries/constants/noRemittanceCountries.constants'
/* FUNCTIONS */
import { showToast, addZerosAtTheEnd, countDecimals } from '@/utils/global-functions';
import { compareNowTimeStampDayAndMonth,epochToDDMMYYYY, epochToDateAndHour, epotchToSpanishTimeZone, epotchToTimeZone, updateDiffs } from '@/utils/date-functions';
import { currencyConvertion, findDefaultRate, findDefaultRateType, findSpecificRate, validateRemittanceRate, validateRemittanceRateFromDestiny } from '@/modules/rates/functions/rates.functions';
import { remittanceBadgeColor, remittanceDetourStatusName } from '@/modules/remittance/functions/remittance.functions';
import { ambassadorLinkAvailable } from '@/modules/ambassador/functions/ambassador.functions';

@Component({
    components: {
        SvgIcon,
        CountryInputDropwdown,
        Toast,
        InfoBanner,
        AppearTransition,
        Table,
        ConfirmationModal,
        Loader,
        SendRemittancePopup,
        RemittanceTypePopup,
        InfoModal,
        Calculator,
        RemittanceDetailPopup,
        ExchangeDetailPopup,
        NoRemittancePopup,
        WelcomePopup
    },
})
export default class Dashboard extends Vue {
    // ambassadorUrl = 'htttps://www.criptoremesa.com/iniciar-registro?cod_ref=092CR30987'
    instagramLink = CRIPTOREMESA.INSTAGRAM;
    exchangeTypes =CAMELCASE_EXCHANGE_TYPES;
    perPage : number = 5;
    publicStatus =  REMITTANCE_PUBLIC_STATUS;
    show = false;
    textFilter : string = ''
    currentFilter : any = {}
    currentOrderFilter : Array<any> = []
    currentView : any = []
    headerFilters: Array<any> = []
    editable: boolean = true;
    showCreateView: boolean = false;
    newViewName: string = '';
    selectedItemId : any = '';
    itemsWithActions : Array<any> = [];
    remittanceTypeModal = false;
    sendRemittanceModal = false;
    noRemittanceModal = false;
    remittanceInProcessInfoModal= {
        title: 'Usted ha iniciado una operación en los pasados 15 minutos y no ha anexado su comprobante de pago. Si desea completarla, presione "Completar", si desea cancelar su operación anterior, presione "Cancelar".',
        open: false
    }
    deleteConfirmationModalPreRemittance = false;
    deleteConfirmationModalPreExchange = false;
    selectedResidCountry: Country = {
        id_country: '',
        name_country: 'Venezuela',
        country_iso_code: 'VE'
    }
    countries = [
        {name: 'chile', iso_code: 'cl', text: 'PEN'},
        {name: 'venezuela', iso_code: 've', text: 'VEF'}
    ]
    /* PRE REMITTANCE */
    preRemittanceCronometer : any = {
        days :'',
        hours:'',
        minutes:'',
        seconds:'',
    }
    preRemittanceDate : any = null;
    /* PRE EXCHANGE */
    preExchangeCronometer : any = {
        days :'',
        hours:'',
        minutes:'',
        seconds:'',
    }
    preExchangeDate : any = null;
    originAmount = 0;
    destinyAmount = 0;
    chartOptions= {
        fill: {
            colors: ['#ffb81c']
        },
        chart: {
          id: 'basic-bar',
          toolbar:{
                show: false
              },
        },
        xaxis: {
          categories: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO'],
          labels: {
                style: {
                    colors: '#fff',
                }
          }
        },
        yaxis: {
          labels: {
                style: {
                    colors: '#fff',
                }
          }
        },
    }
    series= [{
        name: 'Montos',
        data: [30, 40, 45, 50, 49, 60, 70, 91]
    }]
    currencies= [
        {
            name: 'Moneda 1'
        },
        {
            name: 'Moneda 2'
        }
    ]
    $refs: any = {};
    tabIndex = 0;
    // currentDestinyCountryCurrency: CountryCurrency = {iso_cod: '',id_country: null,id_currency: null,name_country: '',country_iso_code: '',viewing_name: ''}
    // currentOriginCountryCurrency: CountryCurrency = {iso_cod: '',id_country: null,id_currency: null,name_country: '',country_iso_code: '',viewing_name: ''}
    // currentOriginCountryCurrencyCalc: CountryCurrency = {iso_cod: '',id_country: null,id_currency: null,name_country: '',country_iso_code: '',viewing_name: ''}
    rateLoader = false;
    rateValue  : Rate = null;
    rateValueName  ={
        type: '',
        name: ''
    };
    rateValueCalc: Rate = null;
    manualRates = MANUAL_RATES;
    rateInfo : {rateType: RateType | null, rangeRate: RangeRate | null} = {rateType: null, rangeRate: null}
    rateType: RateType = null;
    showCopyLinkTooltip = false;
    showPromoDetailsPopup = false;
    welcomeModal = true;
    loading = true;
    remittanceDetailModal = {
        show: false,
        remittance: null
    };
    exchangeDetailModal = {
        show: false,
        exchange: null
    };
    claimStatus = CLAIM_STATUS_ARRAY;
    writingInOriginValGlobal = true;
    
    async mounted() {
       try{
            if (this.preRemittance) this.setPreRemittanceChronometers()
            if (this.preExchange) this.setPreExchangeChronometers()
            await this.fetchResidCountries();
            await this.fetchCountriesCurrencies();
            await this.fetchRateTypes();
            await this.fetchRangeRates();
            let currentDestinyCountryCurrency = this.destinyCountriesCurrencies.find(el=> el.country_iso_code === 'VE')
            await this.saveCurrentDestinyCountryCurrency(currentDestinyCountryCurrency)
            let currentOriginCountryCurrency = this.originCountriesCurrencies.find(el=> el.id_country === this.userData.id_resid_country)
            await this.saveCurrentOriginCountryCurrency(currentOriginCountryCurrency)
            await this.fetchPromoRates({id_origin_country: this.currentOriginCountryCurrency.id_country,id_origin_currency: this.currentOriginCountryCurrency.id_currency,id_destiny_country: this.currentDestinyCountryCurrency.id_country,id_destiny_currency: this.currentDestinyCountryCurrency.id_currency,})
            await this.fetchFullRates({id_origin_country: this.currentOriginCountryCurrency.id_country,id_origin_currency: this.currentOriginCountryCurrency.id_currency,id_destiny_country: this.currentDestinyCountryCurrency.id_country,id_destiny_currency: this.currentDestinyCountryCurrency.id_currency,email_user: this.userData.email_user});
            await this.refreshUserRates();
            this.selectedResidCountry = this.residCountries[0];
            this.setCalculatorRate();
            if (this.preRemittance.pre_remittance && this.showExpiredPreRemittance){
                 await this.fetchLastRemittances({email_user:this.userData.email_user, limit: 3, end_date: null, start_date: null, mode: null });
                 await this.fetchLastExchanges({email_user:this.userData.email_user, limit: 3, end_date: null, start_date: null, mode: null });
            }
            else {
                await this.fetchLastRemittances({email_user:this.userData.email_user, limit: 4, end_date: null, start_date: null, mode: null });
                await this.fetchLastExchanges({email_user:this.userData.email_user, limit: 4, end_date: null, start_date: null, mode: null });
            }
            this.loading = false;
            if (this.$route.params.login)  this.welcomeModal = true;
        }catch(e){
            this.$router.push({name: 'ErrorPage'}).catch();
        }
    }

    get availableDestinyCountryCurrency(){
        if (this.currentOriginCountryCurrency.country_iso_code)
                return  this.destinyCountriesCurrencies.filter(el=> 
                    (el.iso_cod !== this.currentOriginCountryCurrency.country_iso_code && 
                    el.id_currency !== this.currentOriginCountryCurrency.id_currency))
        else return []
    }

    async refreshUserRates(){
        await this.fetchUserRates({id_origin_country: this.currentOriginCountryCurrency.id_country,id_origin_currency: this.currentOriginCountryCurrency.id_currency,id_destiny_country: this.currentDestinyCountryCurrency.id_country,id_destiny_currency: this.currentDestinyCountryCurrency.id_currency,email_user: this.userData.email_user})
    }

    get ambassadorLinkAvailable(){
        return ambassadorLinkAvailable(this.userData)
    }

    get atcPhone(){
        if (this.isEuropeVersion) return EUROPE_PHONE.nonFormattedPhone 
        else {
            let data = WHATSAPP_PHONES.find(el => el.isoCode === this.userData.iso_code_resid_country)
            if (data) return data.nonFormattedPhone
        }
    }

    get isEuropeVersion(){
        return process.env.VUE_APP_EUROPE_VERSION
    }

    /* MODAL */
    remittanceDetailModalStatus(newVal: boolean){
        this.remittanceDetailModal.show = newVal;
    }
    exchangeDetailModalStatus(newVal: boolean){
        this.exchangeDetailModal.show = newVal;
    }
    welcomeModalStatus(newVal: boolean){
        this.welcomeModal = newVal;
    }
    /* END MODAL */


    @Watch('originAmount')
    originAmountChanged(){
        this.setCalculatorRate()
    }

    updateWritingInOriginVal(newVal: boolean) {
        this.writingInOriginValGlobal = newVal;
    }

    setCalculatorRate(){
       if (this.userRates.type === RATE_CATEGORIES.VIP){
           this.rateValue =  this.userRates.rates; 
           this.rateValueName.name = 'Cliente Especial'
           this.rateValueName.type = 'Mejorada'
        }
        if (this.userRates.type === RATE_CATEGORIES.SPECIAL){
            this.rateValue =  this.userRates.rates; 
            this.rateValueName.name = 'Promocional'
            this.rateValueName.type = 'Mejorada'
        }
        else if (this.userRates.type === RATE_CATEGORIES.MANUAL && this.userRates.rates.length > 1){
            if (this.writingInOriginValGlobal)
                this.rateInfo = validateRemittanceRate(this.currentOriginCountryCurrency.id_currency,this.currentDestinyCountryCurrency.id_currency,this.rateTypes,this.rangeRates,this.originAmount)
            else this.rateInfo = validateRemittanceRateFromDestiny(this.currentOriginCountryCurrency.id_currency,this.currentDestinyCountryCurrency.id_currency,this.rateTypes,this.rangeRates,this.destinyAmount,this.userRates.rates)
            this.rateType = this.rateInfo.rateType;
            if (!this.rateType) this.rateType = findDefaultRateType(this.rateTypes);
            if (this.rateType) this.rateValue = findSpecificRate(RATE_CATEGORIES.MANUAL, this.fullRates.manualRates,this.rateType.idRateType);
            else this.rateValue = findDefaultRate(RATE_CATEGORIES.MANUAL, this.fullRates.manualRates)
           this.rateValueName.name = this.rateValue.rate_type_name
           this.rateValueName.type = 'Regular'
       }
       else if (this.userRates && this.userRates.rates.length === 1) {
           this.rateValue = this.userRates.rates[0]
           this.rateValueName.name = this.rateValue.rate_type_name;
           this.rateValueName.type = 'Mejorada';
       }
       else if (this.userRates && this.userRates.rates){
           this.rateValue = this.userRates.rates
           this.rateValueName.name = this.rateValue.rate_type_name;
           this.rateValueName.type = 'Mejorada';
       }
    }

    /* MODAL */
        remittanceTypeModalStatus(newVal: boolean){
            this.remittanceTypeModal = newVal
        }
        sendRemittanceModalStatus(newVal: boolean) {
            this.sendRemittanceModal = newVal;
        }
        noRemittanceModalStatus(newVal: boolean){
            this.noRemittanceModal = newVal;
        }
        openSecondModal(){
           // this.sendRemittanceModal = true;
           if (this.$route.name !== 'SendRemittance' || (this.$route.name === 'SendRemittance' && this.$route.params.modo !== REMITTANCE_TYPES.NO_CAPTURE))
            this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();
        }
        remittanceInProcessModalStatus(newVal: boolean){
            this.remittanceInProcessInfoModal.open = newVal;
        }
    /* END  MODAL */

    @Watch('preRemittance')
    startPreRemittanceChronometer(){
        this.setPreRemittanceChronometers();
    }

    @Watch('preExchange')
    startPreExchangeChronometer(){
        this.setPreExchangeChronometers();
    }

    get totalDestinyAmountCurrencyPreRemittance(){
        return this.$options.filters.currency(this.preRemittance.pre_remittance.totalDestinationRemittance)
    }

    get totalOriginAmountCurrencyPreRemittance(){
        return this.$options.filters.currency(this.preRemittance.pre_remittance.totalOriginRemittance)
    }

     get totalDestinyAmountCurrencyPreExchange(){
        return this.$options.filters.currency(this.preExchange.pre_exchange.netDestinyAmount)
    }

    get totalOriginAmountCurrencyPreExchange(){
        return this.$options.filters.currency(this.preExchange.pre_exchange.netOriginAmount)
    }

    get preRemittanceDateFormatted(){
       return epochToDateAndHour(this.preRemittance.date)
    }
    get preExchangeDateFormatted(){
       return epochToDateAndHour(this.preExchange.date)
    }

    get regularRateFormatted(){
        let secondRate = null;
        let diff = 0;
        if (this.fullRates.improvedRate)
            secondRate = this.improvedRate
        else if (this.fullRates.expectedImprovedRate){
            secondRate = this.fullRates.expectedImprovedRate.rate_factor
        } 
        if (secondRate)
            diff  = countDecimals(this.fullRates.regularRate.rate_factor) - countDecimals(secondRate);
        if (diff<0)
            if (this.fullRates.regularRate.rate_factor.toString().includes('.'))
                return  this.fullRates.regularRate.rate_factor.toString().replace('.',',')+addZerosAtTheEnd(diff)
            else return  this.fullRates.regularRate.rate_factor.toString()+','+addZerosAtTheEnd(diff)
        else  return this.fullRates.regularRate.rate_factor.toString().replace('.',',')
    }

    get regularRateAmountFormatted(){
        if (this.fullRates.regularRate.operation === 'mul')
            return this.fullRates.regularRate.rate_factor.toString().replace('.',',')
        else 
            return (1/this.fullRates.regularRate.rate_factor).toFixed(5).replace('.',',')
    }

    get manualRateConvertion(){
         let convertion = currencyConvertion(this.rateValue,1)
        return this.$options.filters.currency(convertion)
    }

    get normalRangeRate(){
        return this.rangeRates.find(el=> 
        el.rate_type_name === MANUAL_RATES.NORMAL &&
        el.id_country_origin === this.currentOriginCountryCurrency.id_country &&
        el.id_currency_origin === this.currentOriginCountryCurrency.id_currency &&
        el.id_country_destiny === this.currentDestinyCountryCurrency.id_country &&
        el.id_currency_destiny === this.currentDestinyCountryCurrency.id_currency)
    }

    get normalRate(){
        return this.fullRates.manualRates.find(el=>{
           return el.rate_type_name === MANUAL_RATES.NORMAL
        })
    }

    get preferentialRangeRate(){
        return this.rangeRates.find(el=> 
        el.rate_type_name === MANUAL_RATES.PREFERENTIAL &&
        el.id_country_origin === this.currentOriginCountryCurrency.id_country &&
        el.id_currency_origin === this.currentOriginCountryCurrency.id_currency &&
        el.id_country_destiny === this.currentDestinyCountryCurrency.id_country &&
        el.id_currency_destiny === this.currentDestinyCountryCurrency.id_currency)
    }

    get preferentialRate(){
        return this.fullRates.manualRates.find(el=>{
           return  el.rate_type_name === MANUAL_RATES.PREFERENTIAL
        })
    }

    get wholesalerRangeRate(){
        return this.rangeRates.find(el=> 
        el.rate_type_name === MANUAL_RATES.WHOLESALER &&
        el.id_country_origin === this.currentOriginCountryCurrency.id_country &&
        el.id_currency_origin === this.currentOriginCountryCurrency.id_currency &&
        el.id_country_destiny === this.currentDestinyCountryCurrency.id_country &&
        el.id_currency_destiny === this.currentDestinyCountryCurrency.id_currency)
    }

    get wholesalerRate(){
        return this.fullRates.manualRates.find(el=>{
           return el.rate_type_name === MANUAL_RATES.WHOLESALER
        })
    }

    get improvedRate(){
        if (this.fullRates.improvedRate.rates[0] !== undefined){
                return this.fullRates.improvedRate.rates[0].rate_factor
        }
        else{
                return this.fullRates.improvedRate.rates.rate_factor
        }
    }

    get improvedRateFormatted(){
        let diff  = countDecimals(this.improvedRate) - countDecimals(this.fullRates.regularRate.rate_factor)
        if (diff<0)
            if (this.improvedRate.toString().includes('.'))
                return  this.improvedRate.toString().replace('.',',')+addZerosAtTheEnd(diff)
            else return this.improvedRate.toString()+','+addZerosAtTheEnd(diff)
        else return this.improvedRate.toString().replace('.',',')
    }


    get improvedRateAmountFormatted(){
        if (this.fullRates.improvedRate.rates[0] !== undefined){
             if (this.fullRates.improvedRate.rates[0].operation === 'mul')
                return this.fullRates.improvedRate.rates[0].rate_factor.toString().replace('.',',')
             else return (1/this.fullRates.improvedRate.rates[0].rate_factor).toFixed(5).replace('.',',')
        }
        else{
             if (this.fullRates.improvedRate.rates.operation === 'mul')
                return this.fullRates.improvedRate.rates.rate_factor.toString().replace('.',',')
             else return (1/this.fullRates.improvedRate.rates.rate_factor).toFixed(5).replace('.',',')
        }
    }

    get expectedImprovedRateFormatted(){
        let diff  = countDecimals(this.fullRates.expectedImprovedRate.rate_factor) - countDecimals(this.fullRates.regularRate.rate_factor)
        if (diff<0)
            return  this.fullRates.expectedImprovedRate.rate_factor.toString().replace('.',',')+addZerosAtTheEnd(diff)
        else return this.fullRates.expectedImprovedRate.rate_factor.toString().replace('.',',')
    }

    get expectedImprovedRateAmountFormatted(){
        if (this.fullRates.expectedImprovedRate.operation === 'mul')
            return this.fullRates.expectedImprovedRate.rate_factor.toString().replace('.',',')
        else
            return (1/this.fullRates.expectedImprovedRate.rate_factor).toFixed(5).replace('.',',')
    }

    get preRemittanceRateFormatted(){
        return this.preRemittance.pre_remittance.rateValue.rate_factor.toString().replace('.',',')
    }
    get preExchangeRateFormatted(){
        return this.preExchange.pre_exchange.rate.rate_factor.toString().replace('.',',')
    }

    get vipAcumFormatted(){
        if (this.vipRate && this.fullRates.vipAcum){
            return this.$options.filters.currency(this.fullRates.vipAcum)
        }
        return '0,00'
    }

    get vipTotalConvertion(){
        if (this.vipRate){
           // let convertion = currencyConvertion(this.vipRate,this.vipRate.amount_limit)
        //    let convertion = 817.136052 * this.vipRate.amount_limit
            return this.$options.filters.currency(this.fullRates.localAmountLimit)
        }
        return null
    }

    get vipRate(){
        return this.fullRates.manualRates.find(el=> el.rate_type_name === MANUAL_RATES.VIPF)
    }

    get totalVipAcumPercentage() : number{
        if (this.vipRate) {
            let acum = this.fullRates.vipAcum*100/this.fullRates.localAmountLimit;
            if (acum > 100) return 100
            return acum
        }
        return null
    }

    get totalVipAcumPercentageFull(): boolean{
        return this.totalVipAcumPercentage >= 100
    }

    get isBirthDay(){
        if (this.userData.date_birth)
            return compareNowTimeStampDayAndMonth(this.userData.date_birth)
        else return false
    }

    get improvedRateText(){
        if (this.fullRates.improvedRate) {
            if (this.fullRates.improvedRate.type === RATE_CATEGORIES.VIP) return `Aplicas para nuestra promoción Cliente Especial, por eso te ofrecemos ¡una tasa mejorada para envíos desde ${this.currentOriginCountryCurrency.viewing_name}!`
            else if (this.fullRates.improvedRate.type === RATE_CATEGORIES.SPECIAL) return `Aplicas para nuestra promoción '${this.fullRates.improvedRate.rates.special_rate_name}', por eso te ofrecemos ¡una tasa mejorada para envíos desde ${this.currentOriginCountryCurrency.viewing_name}!`
            else if (this.fullRates.improvedRate.type === RATE_CATEGORIES.MANUAL){
                let rate : any = this.fullRates.improvedRate.rates;
                if (rate.length) rate = this.fullRates.improvedRate.rates[0]
                return this.manualRateText(rate)
            }
        }
    }

    get manualRatesNoRange(){
        return this.fullRates.userRates.manualRates.filter(el=>{
            if (el.rate_type_name !== MANUAL_RATES.NORMAL && el.rate_type_name !== MANUAL_RATES.PREFERENTIAL && el.rate_type_name !== MANUAL_RATES.WHOLESALER)
                return el 
        })
    }

    get withdrawalDepositExchanges(){
        return this.exchanges.filter(el=> el.exchangeType.name === this.exchangeTypes.WITHDRAWAL
                                    ||    el.exchangeType.name === this.exchangeTypes.DEPOSIT)
    }

    get buySellExchanges(){
        return this.exchanges.filter(el=> el.exchangeType.name === this.exchangeTypes.BUY
                                    ||    el.exchangeType.name === this.exchangeTypes.SELL)
    }

    sendConsultMessage(id: string, date: string){
        this.$router.push({name: 'Chat', params:{message: `¡Hola! Quiero realizar una consulta con respecto a la operación ${id}, realizada el día ${date}`}}).catch();
    }

    sendConsultExchangeMessage(id: string, date: string,type: string){
        this.$router.push({name: 'Chat', params:{message: `¡Hola! Quiero realizar una consulta con respecto a mi ${type} de criptomonedas ${id}, realizada el día ${date}`}}).catch();
    }

    dateAndHour(epoch: number){
      return epochToDateAndHour(epoch)
    }

    date(epoch: number){
        return epochToDDMMYYYY(epoch)
    }

    getRemittanceBadgeColor(status: string){
       return remittanceBadgeColor(status)
    }

    getRemittanceDetourName(status: string){
       return remittanceDetourStatusName(status)
    }

    manualRateText(rate: Rate){
        if (rate.rate_type_name === MANUAL_RATES.VIPF) return `Has superado montos VIP en los últimos ${rate.amount_days_limit} dias, por eso te ofrecemos ¡una tasa mejorada para envíos desde ${this.currentOriginCountryCurrency.viewing_name}!`
        else if (rate.rate_type_name === MANUAL_RATES.FIRST_OP) return `Recibe tasa mejorada por tu primer envío desde ${this.currentOriginCountryCurrency.viewing_name}`
        else if (rate.rate_type_name === MANUAL_RATES.BIRTHDAY) return `Es el mes de tu cumpleaños, recibe tasa mejorada para tus envíos desde ${this.currentOriginCountryCurrency.viewing_name}`
    }

    copyLink() {
      this.$copyText('¡Envía dinero a tus seres queridos con Bithonor! Es rápido, seguro y con las mejores tasas. Regístrate con mi enlace y participa por premios y beneficios todos los meses '+this.ambassadorlink).then(function (e) {}, function (e) {})
      this.showCopyLinkTooltip = true;
      setTimeout(()=>{this.showCopyLinkTooltip = false}, 1000)
    }

    cancelPreRemittanceConfirmation(){
        this.remittanceInProcessInfoModal.open = false;
        this.deleteConfirmationModalPreRemittance = true;
    }

    cancelPreExchangeConfirmation(){
        this.remittanceInProcessInfoModal.open = false;
        this.deleteConfirmationModalPreExchange = true;
    }

    sendToRemittance(){
      if (this.hasMigratedException || this.userData.id_verif_level === 1 && this.userData.verif_level_apb === null) showToast('Esperando aprobación para verificación de usuario','warning')
      else if (((this.userData.id_verif_level === 1 && this.userData.verif_level_apb === true) || this.userData.id_verif_level > 1)){
        if (!this.preRemittance.id_pre_remittance){
          // this.remittanceTypeModal = true;
          this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();
        }
        else if (this.preRemittance.active && !this.preRemittance.was_expired)
          {
            this.remittanceInProcessInfoModal.open = true;
          }
        else
        this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();
        //  this.remittanceTypeModal = true;
      }else this.noRemittanceModal = true;
    }

    get cannotSendRemittance(){
      return NO_REMITTANCE_COUNTRIES.includes(this.userData.iso_code_resid_country)
    }

    async selectDestinyCurrencyCalculator(countryCurrency: CountryCurrency){
        // this.$refs.loader.showLoader();
        this.rateLoader = true;
        //this.currentDestinyCountryCurrency = countryCurrency;
        await this.saveCurrentDestinyCountryCurrency(countryCurrency)
        await this.fetchPromoRates({id_origin_country: this.currentOriginCountryCurrency.id_country,id_origin_currency: this.currentOriginCountryCurrency.id_currency,id_destiny_country: this.currentDestinyCountryCurrency.id_country,id_destiny_currency: this.currentDestinyCountryCurrency.id_currency,})
        await this.fetchFullRates({id_origin_country: this.currentOriginCountryCurrency.id_country,id_origin_currency: this.currentOriginCountryCurrency.id_currency,id_destiny_country: this.currentDestinyCountryCurrency.id_country,id_destiny_currency: this.currentDestinyCountryCurrency.id_currency,email_user: this.userData.email_user});
        await this.refreshUserRates();
        this.setCalculatorRate();
        this.refreshAmounts();
        this.rateLoader = false
        // this.$refs.loader.hideLoader();
    }

    refreshAmounts(){
        this.originAmount=0;
        this.destinyAmount=0;
    }

    async selectOriginCurrencyCalculator(countryCurrency: CountryCurrency, refresh: boolean){
        // this.$refs.loader.showLoader();
        this.rateLoader = true;
        // this.currentOriginCountryCurrency = countryCurrency;
        await this.saveCurrentOriginCountryCurrency(countryCurrency)
        if (refresh){
            await this.fetchPromoRates({id_origin_country: this.currentOriginCountryCurrency.id_country,id_origin_currency: this.currentOriginCountryCurrency.id_currency,id_destiny_country: this.currentDestinyCountryCurrency.id_country,id_destiny_currency: this.currentDestinyCountryCurrency.id_currency,})
            await this.fetchFullRates({id_origin_country: this.currentOriginCountryCurrency.id_country,id_origin_currency: this.currentOriginCountryCurrency.id_currency,id_destiny_country: this.currentDestinyCountryCurrency.id_country,id_destiny_currency: this.currentDestinyCountryCurrency.id_currency,email_user: this.userData.email_user});
            await this.refreshUserRates();
            this.setCalculatorRate();
        }
        this.refreshAmounts();
        this.rateLoader = false;
        // this.$refs.loader.hideLoader();
    }

    deleteConfirmationModalPreRemittanceStatus(newVal: boolean){
        this.deleteConfirmationModalPreRemittance = newVal;
    }
    deleteConfirmationModalPreExchangeStatus(newVal: boolean){
        this.deleteConfirmationModalPreExchange = newVal;
    }

    setPreRemittanceChronometers(){
        if (this.preRemittance.date && this.preRemittance.was_expired === null){
            this.preRemittanceDate = epotchToTimeZone(this.preRemittance.date);
            let minutesToAdd = 15 * 60;
            var intervalId = undefined;     
                intervalId = setInterval(() => {
                    let expirationDate = epotchToTimeZone(this.preRemittance.date+minutesToAdd)
                    if (this.preRemittance.date)
                        this.updatePreRemittanceConsultDiffs(new Date(),expirationDate);
                },1000); 
        }   
    }

    setPreExchangeChronometers(){
        if (this.preExchange.date && this.preExchange.was_expired === null){
            this.preExchangeDate = epotchToTimeZone(this.preExchange.date);
            let minutesToAdd = 15 * 60;
            var intervalId = undefined;     
                intervalId = setInterval(() => {
                    let expirationDate = epotchToTimeZone(this.preExchange.date+minutesToAdd)
                    if (this.preExchange.date)
                        this.updatePreExchangeConsultDiffs(new Date(),expirationDate);
                },1000); 
        }   
    }

    updatePreRemittanceConsultDiffs(initDate: any, endDate: any){
       this.preRemittanceCronometer =  updateDiffs(initDate,endDate)
    //    if (this.preRemittanceCronometer.hours===0 && this.preRemittanceCronometer.minutes === 0 && this.preRemittanceCronometer.seconds === 0){
    //        this.preRemittance.was_expired = true
    //    } 
    }

    updatePreExchangeConsultDiffs(initDate: any, endDate: any){
       this.preExchangeCronometer =  updateDiffs(initDate,endDate)
    }

    async cancelPreRemitanceConfirmed(){
        this.deleteConfirmationModalPreRemittance = false;
        this.$refs.loader.showLoader();
        if (!await this.cancelPreRemitance(this.preRemittance.id_pre_remittance))
        showToast('Ha ocurrido un error cancelando la operación','success')
        this.$refs.loader.hideLoader();
    }

    async cancelPreExchangeConfirmed(){
        this.deleteConfirmationModalPreExchange = false;
        this.$refs.loader.showLoader();
        if (await this.cancelPreExchange(this.preExchange.id_pre_exchange))
            showToast('Su compra se ha cancelado exitosamente','success')
        else showToast('Ha ocurrido un error cancelando su compra','success')
        this.$refs.loader.hideLoader();
    }

    completePreRemittance(){
        this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();
    }

    completePreExchange(){
        this.$router.push({name: 'CryptoBuy'}).catch();
    }

    get showExpiredPreRemittance(){
        if ((this.preRemittance.date_last_shown && 
            this.preRemittance.was_expired &&
            ((Date.now() / 1000) < this.preRemittance.date_last_shown)) || 
            (!this.preRemittance.was_expired && this.preRemittance.active))
            return true
        else return false
    }

    get showExpiredPreExchange(){
        if ((this.preExchange.date_last_shown && 
            this.preExchange.was_expired &&
            ((Date.now() / 1000) < this.preExchange.date_last_shown)) || 
            (!this.preExchange.was_expired && this.preExchange.active))
            return true
        else return false
    }

    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    @auth.Getter(AuthMethods.getters.GET_AMBASSADOR_LINK)
    ambassadorlink!: string;
    @auth.Getter(AuthMethods.getters.HAS_MIGRATED_EXCEPTION)
    hasMigratedException!: boolean;
    /* COUNTRIES */
    @countries.Action(CountriesMethods.actions.FETCH_ALL_RESIDENCE_COUNTRIES)
    fetchResidCountries!: () => Promise<boolean>
    @countries.Getter(CountriesMethods.getters.GET_ALL_RESIDENCE_COUNTRIES)
    residCountries!: Country[];
    @countries.Action(CountriesMethods.actions.FETCH_ORIGN_DEST_COUNTRY_CURRENCY)
    fetchCountriesCurrencies!: () => Promise<boolean>
    @countries.Getter(CountriesMethods.getters.GET_DESTINY_COUNTRY_CURRENCY)
    destinyCountriesCurrencies!: CountryCurrency[];
    @countries.Getter(CountriesMethods.getters.GET_ORIGIN_COUNTRY_CURRENCY)
    originCountriesCurrencies!: CountryCurrency[];
    @countries.Getter(CountriesMethods.getters.GET_GLOBAL_DESTINY_COUNTRY_CURRENCY)
    currentDestinyCountryCurrency!: CountryCurrency;
    @countries.Action(CountriesMethods.actions.SAVE_GLOBAL_DESTINY_COUNTRY_CURRENCY)
    saveCurrentDestinyCountryCurrency!: (countryCurrency: CountryCurrency) => Promise<boolean>;
    @countries.Getter(CountriesMethods.getters.GET_GLOBAL_ORIGIN_COUNTRY_CURRENCY)
    currentOriginCountryCurrency!: CountryCurrency;
    @countries.Action(CountriesMethods.actions.SAVE_GLOBAL_ORIGIN_COUNTRY_CURRENCY)
    saveCurrentOriginCountryCurrency!: (countryCurrency: CountryCurrency) => Promise<boolean>;
    /* REMITTANCES */
    @remittance.Getter(RemittanceMethods.getters.GET_PRE_REMITTANCE)
    preRemittance!: PreRemittance;
    @remittance.Action(RemittanceMethods.actions.CANCEL_PRE_REMITTANCE)
    cancelPreRemitance!:(pre_remittance_id: number)=> Promise<boolean>
    @remittance.Action(RemittanceMethods.actions.FETCH_REMITTANCES)
    fetchLastRemittances!: (data: {email_user: string, limit: number, end_date: number, start_date: number, mode: string}) => Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_REMITTANCES)
    remittances!: Remittance[];
    /* RATES */
    @rates.Action(RatesMethods.actions.FETCH_RATE_TYPES)
    fetchRateTypes!:()=>Promise<boolean>
    @rates.Action(RatesMethods.actions.FETCH_RANGE_RATES)
    fetchRangeRates!:()=>Promise<boolean>
    @rates.Getter(RatesMethods.getters.GET_RANGE_RATES)
    rangeRates!: RangeRate[];
    @rates.Getter(RatesMethods.getters.GET_RATE_TYPES)
    rateTypes!: RateType[];
    @rates.Action(RatesMethods.actions.FETCH_FULL_RATES)
    fetchFullRates!: (data: any) => Promise<boolean>
    @rates.Getter(RatesMethods.getters.GET_FULL_RATES)
    fullRates!: FullRate;
    @rates.Action(RatesMethods.actions.FETCH_PROMO_RATES)
    fetchPromoRates!:(data: any) => Promise<boolean>
    @rates.Getter(RatesMethods.getters.GET_PROMO_RATES)
    promoRates!: Rate[];
    @rates.Action(RatesMethods.actions.FETCH_USER_RATES)
    fetchUserRates!: (data: any) => Promise<boolean>
    @rates.Getter(RatesMethods.getters.GET_USER_RATES)
    userRates!: {type: string, rates: any};
    /* NOTIFICATIONS */
    @notifications.Getter(NotificationsMethods.getters.GET_MARQUEE_NOTIFICATIONS)
    marqueeNotifications!: Notif[]
    /* EXCHANGES */
    @exchange.Getter(ExchangeMethods.getters.GET_PRE_EXCHANGE)
    preExchange!: PreExchange;
    @exchange.Action(ExchangeMethods.actions.CANCEL_PRE_EXCHANGE)
    cancelPreExchange!: (preExchangeId: number) => Promise<boolean>
    @exchange.Action(ExchangeMethods.actions.FETCH_EXCHANGES)
    fetchLastExchanges!: (data: {email_user: string, limit: number, end_date: number, start_date: number, mode: string}) => Promise<boolean>
    @exchange.Getter(ExchangeMethods.getters.GET_EXCHANGES)
    exchanges!: Exchange[];
}
