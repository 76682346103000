import {CRYPTO_CURRENCIES} from '@/modules/currencies/constants/currencies.constants'
import {PAIRS} from '@/modules/exchange/constants/pairs.constants'
import {RATE_OPERATIONS} from '@/modules/rates/constants/rate.constants';

export function getPairs(origin: string,destiny: string){
    if ((origin === CRYPTO_CURRENCIES.BTC && destiny === CRYPTO_CURRENCIES.USDT)
       ||
       (origin === CRYPTO_CURRENCIES.USDT && destiny === CRYPTO_CURRENCIES.BTC))
    return PAIRS.BTCUSDT
    else return 'Invalid Pair'
}


export function getPairOperation(pair: string,origin:string){
    switch(pair){
        case PAIRS.BTCUSDT:
            if (origin === CRYPTO_CURRENCIES.BTC) return RATE_OPERATIONS.MULTIPLICATION
            else return RATE_OPERATIONS.DIVISION;
        default:
            return RATE_OPERATIONS.MULTIPLICATION
    }
}